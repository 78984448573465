import callAPI from '../../commonservices/APIRequests';
import { updateBankPayment } from '../Reducers/BankDetails';
import { updateDDRCart, updatePaymentsCart } from '../Reducers/Cart';
import { updateDrawdownRequest } from '../Reducers/DrawdownRequest';
import { updateNotifications } from '../Reducers/Notifications';
import { updateTasksList } from '../Reducers/Services/updateTaskslist';
import { upDateMenu, setHoliDays, setLoanTypes, setStates, hideLoader, showLoader, setNoteTagData, setSampleBankDocuments, setRiskStatusListDealers, setTagFields, setTopTenLists, updateMasterMenuById, setMenuRoutes } from '../Reducers/Shared';
import { onSelectingBank as selectBank } from '../Reducers/BankDetails';
let userDetails = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {};





export const onSelectingBank = (accountId, ischecked, banksList, dealer_id, checkBankVisibility = false, isBankVisible = false, from = null) => {
    return dispatch => {
        dispatch(showLoader())
        const paymentlist = banksList ? JSON.parse(JSON.stringify(banksList)) : {};
        paymentlist.accounts.map((item) => {
            item.isPrimary = item._id === accountId ? ischecked : false;
        })
        dispatch(onBankSelect(paymentlist));
        let req;
        if (from === 'supplier') {
            req = { _id: accountId, supplier_id: dealer_id }
        } else {
            req = { _id: accountId, dealer_id: dealer_id }
        }

        callAPI.post('/payment/api/v1/payments/setPrimaryAccount', req)
            .then((response) => {

                if (response.status === 200) {
                    if (!checkBankVisibility) {
                        dispatch(getBankList(dealer_id));
                        dispatch(hideLoader());
                    }
                    else if (checkBankVisibility && !isBankVisible) {

                        dispatch(onSelectBanksVisible([{ _id: accountId, isBankVisible: !isBankVisible }], dealer_id, banksList, accountId, ischecked))
                    }
                    else {
                        dispatch(getBankList(dealer_id));
                        dispatch(hideLoader());
                    }
                }

                else {
                    dispatch(hideLoader());
                    console.log("Error on Primary selection...");
                }

            })
    }

}

export const onBankSelect = (bankDetails) => {

    return selectBank(bankDetails);

}

export const getBankDetails = (id, from = null) => {

    const userDetails = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {};

    const isMultiDealerAdmin = localStorage.getItem('isMultiDealerAdmin') ? JSON.parse(localStorage.getItem('isMultiDealerAdmin')) : false;
    const multiDealerId =  localStorage.getItem('multiDealerId') ? localStorage.getItem('multiDealerId') : null;

    const isMultiSupplierAdmin = localStorage.getItem('isMultiSupplierAdmin') ? JSON.parse(localStorage.getItem('isMultiSupplierAdmin')) : false;
    const multiSupplierId =  localStorage.getItem('multiSupplierId') ? localStorage.getItem('multiSupplierId') : null;


    let req = {
        [from === 'supplier' ? 'supplier_id' : 'dealer_id']: userDetails && userDetails.organization && userDetails.organization.toLowerCase() === 'dealer' ? userDetails._id : id
    };
    if ((userDetails && userDetails.organization && userDetails.organization.toLowerCase() === 'lender') && (from === 'supplier'))
        req.showCashDropdown = true;

    if (isMultiSupplierAdmin && multiSupplierId) {
        req.supplier_id = multiSupplierId;
    }

    if (isMultiDealerAdmin && multiDealerId) {
        req.dealer_id = multiDealerId;
    }

    return dispatch => {

        callAPI.post('/payment/api/v1/payments/paymentWidget', req)
            .then((response) => {

                if (response.status === 200) {
                    dispatch(updateBankPayment(response.data));
                }
                else {
                    dispatch(updateBankPayment({}));
                }

            })

    }

}

export const getDDRCart = (id) => {

    return dispatch => {

        return callAPI.get('/vehicle/api/v1/dealers/shoppingCart', id ? { dealer_id: id } : {})
            .then((response) => {

                if (response.status === 200) {
                    dispatch(updateDDRCart({ data: response.data?.vehicles, totalCost: response.data?.totalCost }));

                }
                else {
                    dispatch(updateDDRCart({ data: [], totalCost: 0 }));

                }

            })

    }

}

export const getPaymentsCart = (reqData) => {

    return dispatch => {

        return callAPI.get('/payment/api/v1/payments/getCart', (reqData?.type !== 'supplier' ? { dealer_id: reqData } : reqData))

            .then((response) => {

                if (response.status === 200) {

                    if (response.data.cartItems && response.data.cartItems.length > 0) {
                        response.data.cartItems.forEach(item => {
                            item.isVehicleSold = false;
                        })
                    }
                    dispatch(updatePaymentsCart({ data: response.data?.cartItems, addresses: response.data?.addressList, totalCost: response.data?.totalCount }));

                }
                else {
                    dispatch(updatePaymentsCart({ data: [], addresses: [], totalCost: [] }));

                }

            })

    }

}

export const getNotifications = (id) => {

    return dispatch => {

        return callAPI.get('/workflow/api/v1/notification/get', id ? { dealer_id: id } : {})
            .then((response) => {

                if (response.status === 200) {

                    dispatch(updateNotifications(response.data.notifications))

                }
                else {
                    dispatch(updateNotifications([]))

                }

            })

    }

}

export const readNotifications = (id) => {

    return dispatch => {

        return callAPI.post('/workflow/api/v1/notification/read', id ? { dealer_id: id } : {})
            .then((response) => {

                if (response.status === 200) {

                    dispatch(updateNotifications([]))

                }
                else {

                    console.log(response.message);

                }

            })

    }

}

export const getDrawdownRequest = (id, dealer_id) => {

    return dispatch => {

        return callAPI.get('/vehicle/api/v1/dealers/getDrawDown', { vehicleId: id, dealer_id: dealer_id })
            .then((response) => {

                if (response.status === 200) {

                    const data = {
                        ...response.data.vehicle,
                        documents: response.data.documents
                    }

                    dispatch(updateDrawdownRequest(data));

                }
                else {
                    dispatch(updateDrawdownRequest({}));

                }

            })

    }

}

export const getBankList = (id, bankId = "", refreshBankId = false, from = '') => {

    return dispatch => {

        dispatch(showLoader());

        const isMultiDealerAdmin = localStorage.getItem('isMultiDealerAdmin') ? JSON.parse(localStorage.getItem('isMultiDealerAdmin')) : false;
        const multiDealerId =  localStorage.getItem('multiDealerId') ? localStorage.getItem('multiDealerId') : null;

        const isMultiSupplierAdmin = localStorage.getItem('isMultiSupplierAdmin') ? JSON.parse(localStorage.getItem('isMultiSupplierAdmin')) : false;
        const multiSupplierId =  localStorage.getItem('multiSupplierId') ? localStorage.getItem('multiSupplierId') : null;

        let paymentReq = {
            [from === 'supplier' ? 'supplier_id' : 'dealer_id']: userDetails && userDetails.organization && userDetails.organization.toLowerCase() === 'dealer' ? userDetails._id : id,
        }
        
        if (isMultiDealerAdmin && multiDealerId) {
            paymentReq.dealer_id = multiDealerId;
        }

        if (isMultiSupplierAdmin && multiSupplierId) {
            paymentReq.supplier_id = multiSupplierId;
        }
        return callAPI.post('/payment/api/v1/payments/paymentWidget', paymentReq)
            .then((response) => {

                if (response.status === 200) {
                    dispatch(hideLoader());
                    if (refreshBankId) {
                        response.data?.accounts?.forEach((item) => {
                            if (item._id === bankId) {
                                item.flip = false;
                            }
                        })
                    }
                    dispatch(updateBankPayment(response?.data));


                }
                else {

                    dispatch(updateBankPayment({}));
                    dispatch(hideLoader());
                }

            })

    }

}

export const getLoanTypes = () => {
    return dispatch => {

        return callAPI.post('/workflow/api/v1/classification/getValues', { type: 'Funding Request Type' })
            .then((response) => {

                if (response.status === 200 || response.status === 304) {
                    const obj = {}

                    response.data.classifications.forEach(item => {

                        obj[item.value] = item.label
                    })
                    dispatch(setLoanTypes(obj));

                }

            })
    }

}

export const getMenu = () => {

    return dispatch => {

        return callAPI.get('/workflow/api/v1/menu/getDynamicMenu', {})
            .then((response) => {

                if (response.status === 200 || response.status === 304) {
                    dispatch(upDateMenu(response.data.dynamicMenu && response.data.dynamicMenu.length > 0 ? response.data.dynamicMenu : []))
                    const routes = response.data.dynamicMenu.map((item)=>item.route)
                    dispatch(setMenuRoutes(routes))
                }
                else {

                    //dispatch({ type: "UPDATEMENU", menu: [] });

                }

            })

    }

}

export const getStates = () => {

    return dispatch => {

        return callAPI.get('/workflow/api/v1/shared/getStates', {})
            .then((response) => {

                if (response.status === 200 || response.status === 304) {
                    if (response.data.states && response.data.states.length > 0) {
                        response.data.states.forEach(item => {
                            item.label = item.abbreviation;
                            item.value = item.abbreviation;
                        })
                    }

                    dispatch(setStates(response.data.states?.length > 0 ? response.data.states.sort((a, b) => a.label.localeCompare(b.label)) : []));

                }

            })

    }

}
export const getLenderTasks = () => {

    return dispatch => {

        return callAPI.get('/lender/api/v1/lender/lenderTasks', {})
            .then((response) => {

                if (response.status === 200) {

                    const data = response.data.tasks;
                    data.map(item => {
                        item.dealerName = item.dealerDetails?.dealerName ?? item.supplierId?.dealerName;
                        item.dealerReference = item.dealerDetails?.sopraDealerReference ?? item.supplierId?.sopraSupplierReference;
                        item.name = item.status && item.status.toLowerCase().indexOf('undo') > -1 ? 'Undo ' + item.name : item.name;
                    });

                    dispatch(updateTasksList({ taskList: data, lenderTaskcounts: response.data }));

                }
                else {

                    dispatch(updateTasksList({ taskList: [], lenderTaskcounts: {} }));

                }
            })

    }

}

export const getBankHolidays = () => {

    return dispatch => {
        dispatch(showLoader())


        return callAPI.post('/workflow/api/v1/events/getBankHolidays', {})
            .then((response) => {

                if (response.status === 200) {

                    dispatch(setHoliDays(response.data.bankHolidays.map(item => new Date(item))));
                    dispatch(hideLoader());

                }
                else {
                    dispatch(setHoliDays([]));
                    dispatch(hideLoader());

                }

            })

    }

}

export const onSelectBanksVisible = (data, dealer_id) => {
    return dispatch => {
        dispatch(showLoader());
        callAPI.post('/payment/api/v1/plaid/selectBanksToVisible', data)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(hideLoader());
                    dispatch(getBankList(dealer_id));
                }
                else {
                    console.log("Error on setting bank visibility...");
                    dispatch(hideLoader());
                    dispatch(getBankList(dealer_id));
                }
            })
    }
}

export const getMasterMenuById = (id) => {
    return dispatch => {
        dispatch(showLoader());
        callAPI.get(`/workflow/api/v1/menu/getMasterMenuById?user_id=${id}`,)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(hideLoader());
                    dispatch(updateMasterMenuById(response.data.dynamicMenu))
                }
                else{
                    dispatch(hideLoader());
                }
            })
    }
}
export const getNotetagData = () => {
    return dispatch => {
        dispatch(showLoader());
        callAPI.post('/workflow/api/v1/classification/getValues', { type: 'Note Tag' })
            .then((response) => {
                if (response.status === 200) {
                    response.data.classifications.forEach(item => {
                        item.label = item.value;
                    })
                    let sortedNoteTags = response.data?.classifications?.sort((a, b) => a.label.localeCompare(b.label));
                    dispatch(setNoteTagData(sortedNoteTags))
                }
                else {
                    dispatch(setNoteTagData([]))
                }
            }).finally(() => {
                dispatch(hideLoader());
            })
    }
}

export const getDealerRiskStatusTypes = () => {
    return dispatch => {
        dispatch(showLoader());
        callAPI.post('/workflow/api/v1/classification/getValues', { type: 'dealer_risk_status' })
            .then((response) => {
                if (response.status === 200) {
                    response.data.classifications.forEach(item => {
                        item.label = item.value;
                    })
                    dispatch(setRiskStatusListDealers(response.data.classifications))
                }
                else {
                    dispatch(setRiskStatusListDealers([]))
                }
            }).finally(() => {
                dispatch(hideLoader());
            })
    }
}

export const getTopTenLists = () => {
    return dispatch => {
        dispatch(showLoader());
        callAPI.post('/workflow/api/v1/classification/getValues', { type: 'Dashboard_Risk_Filters' })
            .then((response) => {
                if (response.status === 200) {
                    dispatch(setTopTenLists(response.data.classifications))
                }
                else {
                    dispatch(setTopTenLists([]))
                }
            }).finally(() => {
                dispatch(hideLoader());
            })
    }
}

export const getTagTypes = (tagType = "") => {
    return dispatch => {
        callAPI.post('workflow/api/v1/classification/getValues', { type: tagType })
            .then((response) => {
                if (response.status === 200) {
                    const classifications = response.data?.classifications || [];
                    let metadata = classifications[0]?.metadata || [];

                    if (tagType === "user_tags") {
                        metadata = metadata.reduce((accumulator, currentItem) => {
                            accumulator.push({
                                label: currentItem.name,
                                value: currentItem.name,
                                ...currentItem
                            });
                            return accumulator;
                        }, []);
                    }

                    const sortedTags = metadata.sort((a, b) => a.label.localeCompare(b.label));

                    switch (tagType) {
                        case "user_tags":
                            dispatch(setTagFields({ dealerTagsData: sortedTags, isDealerTagsLoaded: true }))
                            break;
                        case "asset_tags":
                            const activeAssetTags = sortedTags.filter(tag => tag.active);
                            dispatch(setTagFields({ assetTagsData: activeAssetTags, isAssetTagsLoaded: true }))
                            break;
                        default:
                            break;
                    }

                }
                else {
                    switch (tagType) {
                        case "user_tags":
                            dispatch(setTagFields({ dealerTagsData: [], isDealerTagsLoaded: true }))
                            break;
                        case "asset_tags":
                            dispatch(setTagFields({ assetTagsData: [], isAssetTagsLoaded: true }))
                            break;
                        default:
                            break;
                    }
                }
            })
    }
}


export const getSampleBankDocuments = () => {
    return dispatch => {
        callAPI.post('/workflow/api/v1/classification/getValues', { type: 'bankDocumentURL' })
            .then((response) => {
                if (response.status === 200) {
                    dispatch(setSampleBankDocuments(response?.data?.classifications.length > 0 ? response.data.classifications : []))
                } else {
                    dispatch(setSampleBankDocuments([]))
                }
            })
    }
}
